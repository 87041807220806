import Vue from 'vue'
import App from './App'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'
import router from './router'
import css from "./assets/css/main.css";
import store from "./store";
import Paginate from 'vuejs-paginate'

Vue.component('paginate', Paginate)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/notFound');
  } else {
    next();
  }
});

new Vue({
  store,
  router,
  css,
  render: (h) => h(App),
}).$mount("#app");
