import Vuex from "vuex";
import Vue from "vue";
import router from '../router'
import createPersistedState from 'vuex-persistedstate'
import {
  db,
  auth
} from "../plugins/firebase";
Vue.use(Vuex);

const getDefaultState = () => {
  return {
    isLogin: false,
    news: []
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    setLogin (state, isLogin) {
      state.isLogin = isLogin
    },
    setNews (state, news) {
      state.news = news
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  actions:{
    async auth(){
      return new Promise(resolve => {
        auth.onAuthStateChanged(async function(user) {
          if (user) {
            resolve()
          } else {
            await auth.signInAnonymously().catch(e => {
              console.log(e)
            })
            resolve()
          }
        })
      })
    },
    async getNews({ commit }) {
      try {
        const news =  await db.collection('news')
                              .orderBy('releaseDate', 'desc')
                              .get()
                              .then((doc) => {
                                if(doc.docs){
                                    return doc.docs.map(d => {
                                      return d.data()
                                    })
                                } else {
                                    return []
                                }
                              })

         commit("setNews", news)
      }catch(e){
        console.log(e)
      }
    },
    async logout ({ commit  }) {
      commit('setLogin', false)
      commit('reset')
      await router.push({ path: '/maintenanceLogin' })
    }
  }
})
