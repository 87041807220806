<template>
  <div id="top">
    <transition name="fade">
    <div id="menu">
      <img class="logo" src="../assets/img/logo.svg" @click="homePage">
    </div>
    </transition>

    <div id="main">
      <div id="news">
        <div class="homeButton" @click="homePage">HOME</div>
        <div class="pagetitle">NEWS一覧</div>
        <div class="list">
          <div class="item" v-for="item in getNews" :key="item.documentId">
            <div class="releasedate">{{item.releaseDate}}</div>
            <div class="title"><a :href="item.url" target="_blank">{{item.title}}</a></div>
          </div>

        </div>
      </div>

      <div class="paginateArea">
        <paginate
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'">
        </paginate>
      </div>

      <div id="footer">Copyright © INDIVISION Inc. All Rights Reserved.</div>
    </div>

    <transition name="fade">
      <div id="pagetop" v-show="pageTopY > 300" @click="pageTop"></div>
    </transition>
  </div>
</template>

<style scoped>
#pagetop{
  background-image: url("../assets/img/up.svg");
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;
  z-index: 1000;
  cursor: pointer;
}

#pagetop:hover{
  opacity: 0.7;
}

#menu{
  position: fixed;
  display: flex;
  height: 70px;
  width: 100%;
  border-bottom: #FFFFFF 1.5px solid;
  background-color: #1DADE5;
  z-index: 10;
}

#menu img{
  height: 38px;
  margin-top: 16px;
  margin-left: 24px;
  cursor: pointer;
}

#menu img:hover{
  opacity: 0.7;
}

#menu .menu_item{
  height: 70px;
  width: 60%;
  position: absolute;
  right: 0px;
  text-align: end;
}

#footer{
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #FFFFFF;
  font-size: 12px;
}

#header{
  position: relative;
  width: 100%;
  height: 51vw;
  top: 70px;
  background-image: url("../assets/img/top_bg_logo.svg"), url("../assets/img/top_bg.webp");
  background-repeat: no-repeat;
  background-position: center 55%, center center;
  background-size: 30%, 100%;
  z-index: 1;
}

#header .header_title{
  color: #FFFFFF;
  font-size: 2.93vw;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 7.32vw;
  text-shadow: 1px 1px 9px rgba(12, 12, 119, 0.75), -1px -1px 9px rgba(12, 12, 119, 0.75);
}

.pagetitle{
  width: 150px;
  height: 40px;
  color: #0071BC;
  background-color: #FFFFFF;
  margin: 30px auto;
  text-align: center;
  line-height: 40px;
  font-size: 21px;
  font-weight: 500;
}


#main{
  width: 100%;
  height: auto;
  background-image: url("../assets/img/background-image.svg");
  background-size: 120%;
  background-position: center 0;
  background-color: #0071BC;
}

#news .homeButton{
  width: 120px;
  height: 34px;
  color: #FFFFFF;
  background-color: #2CA6E0;
  text-align: center;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 30px;
}

#news .homeButton:hover{
  background-color: #4fc0ed;
}


#news{
  position: relative;
  padding-top: 100px;
}

#news .list{
  width: 70%;
  margin: 30px auto;
  overflow-y: auto;
}

#news .releasedate{
  color: #FFF000;
  font-size: 16px;
  font-weight: 700;
}

#news .title{
  margin-bottom: 30px;
}

#news .title a{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 75px;
}

#news .title a:hover{
  text-decoration: underline;
}

.paginateArea{
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 720px) {
  body{
    overflow-x: hidden !important;
  }

  #news .title a{
    font-size: 14px;
  }

}

</style>

<style>
.pagination{
  margin: 20px auto !important;
}

.pagination > li > a, .pagination > li > span{
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color:#039BE5;
  font-size: 16px;
  font-weight: 700;
  outline: none;
}

.pagination > li:first-child > a, 
.pagination > li:first-child > span,
.pagination > li:last-child > a, 
.pagination > li:last-child > span {
    border-radius: 20px;
}

.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus{
  background-color: #039BE5;
  border-color: #039BE5;
}

.pagination > li > a:hover, 
.pagination > li > span:hover, 
.pagination > li > a:focus, 
.pagination > li > span:focus{
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.pagination > .disabled > span, 
.pagination > .disabled > span:hover, 
.pagination > .disabled > span:focus, 
.pagination > .disabled > a, 
.pagination > .disabled > a:hover, 
.pagination > .disabled > a:focus{
  color: #FFFFFF;
  background:rgba(255,0,0,0);
  border: none;
}

.pagination > li:first-child.disabled > span, 
.pagination > li:first-child.disabled > a,
.pagination > li:last-child.disabled > span, 
.pagination > li:last-child.disabled > a
{
  display: none;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: 'top',
  data(){
    return {
      pageTopTimer: 0,
      pageTopY: 0,
      pageNum: 1,
      pagePerCount: 10,
      pageCount: 1,
    }
  },
  mounted() {
    // pageCountを計算
    this.pageCount = Math.ceil(this.news.length / this.pagePerCount);
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    ...mapState([
      'news',
    ]),
    getNews(){
      return this.news.slice((this.pageNum - 1) * this.pagePerCount, this.pageNum * this.pagePerCount)
    }
  },
  methods: {
    handleScroll() {
        if (this.pageTopTimer) return;
        this.pageTopTimer = setTimeout(() => {
          this.pageTopY = window.scrollY;
          clearTimeout(this.pageTopTimer);
          this.pageTopTimer = 0;
        }, 100);
    },
    pageTop(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    homePage(){
      this.$router.push('/')
    },
    clickCallback (newPageNum){
      this.pageNum = newPageNum;
    }
  }
}
</script>
