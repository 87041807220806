import Vue from 'vue'
import Router from 'vue-router'

import top from '@/components/top'
import news from '@/components/news'
import notFound from '@/components/notFound'

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    { path: "/", name: "top", component: top },
    { path: "/news", name: "news", component: news },
    { path: '*/.*', name: "notFound", component: notFound },
    { path: '*', name: "notFound", component: notFound },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
