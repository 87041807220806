<template>
  <div id="top">
    <transition name="fade">
    <div id="menu">
      <img class="logo" src="../assets/img/logo.svg" @click="pageTop">
      <div class="menu_item">
        <ul id="nav">
          <li><span  @click="scroll('#vision')">VISION</span></li>
          <li><span  @click="scroll('#news')">NEWS</span></li>
          <li><span  @click="scroll('#solution')">SOLUTION</span></li>
          <li><span  @click="scroll('#about')">ABOUT US</span></li>
          <li><span  @click="scroll('#products')">PRODUCTS</span></li>
        </ul>
      </div>
      <el-button id="hamburger" icon="el-icon-menu" @click="hamburgerMenu = !hamburgerMenu"></el-button>
    </div>
    </transition>

    <div id="header">
      <div class="newsarea">
        <div class="title">
          <div class="newstitle">NEWS</div>
        </div>
        <div class="list">
          <div class="item" v-for="item in news" :key="item.documentId">
            <div class="releasedate">{{item.releaseDate}}</div>
            <div class="title"><a :href="item.url" target="_blank">{{item.title}}</a></div>
          </div>
        </div>
      </div>

      <div class="header_title">データ主導の真実が、既成概念を取り払う</div>
    </div>

    <div id="main">
      <div id="vision">
        <div class="pagetitle">VISION</div>
        <p class="visiontext">
          産業革命～情報革命～シンギュラリティ時代において、社会経済を取り巻く環境は激変している。<br>
          なかでもAI/IoT/Block chainなどに関する新しいテクノロジーの進化やスマホやウェアラブルデバイスの爆発的普及が生活者の行動・心理を大きく変容させている。<br>
          これまでの事業活動・マーケティング活動は企業や団体を基点として、消費活動における生活者へのアプローチを中心に講じられてきたが、一個人のライフタイムにおける生活行動のデータ集約とリアルタイム活用を可能とするプラットフォームの利活用により社会経済・消費行動・マーケティングの概念・定義を根底から変革してゆく。  
        </p>

        <p class="visiontitle">個人を基点とした新しい社会基盤創出</p>
        <table class="visiontable">
          <tr>
            <td class="vision">VISION</td>
            <td class="visioncontent">個人基点のデータ集積・分析・利活用による新たな最適化社会の実現</td>
          </tr>
          <tr>
            <td class="vision">MISSION</td>
            <td class="visioncontent">個人と企業との持続的かつリアルタイムなエンゲージメント構築を、多様なデータソースの融合とオムニチャネルでのタッチポイントの創出によって実現・支援する。</td>
          </tr>
        </table>

        <div class="visioArea">
          <div class="visionmean">
                <span class="word">INDIVIDUAL</span><br><span class="mean">個人</span>
          </div>
          <div class="operator pl">＋</div>
          <div class="visionmean">
                <span class="word">VISION</span><br><span class="mean">可視化・戦略化</span>
          </div>
          <div class="operator eq">＝</div>

          <div class="logo">
                        <img src="../assets/img/logo.svg">
          </div>
        </div>
      </div>

      <div id="news">
        <div class="pagetitle">NEWS</div>
        <div class="list">
          <div class="item" v-for="item in getTop5News" :key="item.documentId">
            <div class="releasedate">{{item.releaseDate}}</div>
            <div class="title"><a :href="item.url" target="_blank">{{item.title}}</a></div>
          </div>

          <div class="pastNewsButton" @click="newsPage">過去のNEWS</div>
        </div>
      </div>

      <div id="solution">
        <div class="pagetitle">SOLUTION</div>
        <ul class="solutionlist">
          <li>
            <a href="https://relay42.com/" target="_blank">
            <img src="../assets/img/solution1.png">
            </a>
            <p class="text">
              <a href="https://relay42.com/" target="_blank">CDPの概念を変えるカスタマージャーニー・オーケストレーション『Relay42』</a>
            </p>
          </li>
          <li>
            <a href="https://eventory.jp/" target="_blank">
            <img src="../assets/img/solution2.png">
            </a>
            <p class="text">
              <a href="https://eventory.jp/" target="_blank">イベントのあらゆるアクティビティをデータ化するイベントDXプラットフォーム『Eventory』</a>
            </p>
          </li>
        </ul> 
      </div>

      <div id="about">
        <div class="pagetitle">ABOUT US</div>
        <ul class="memberlist">
          <li>
            <div class="title">
              <img src="../assets/img/zak.png">
              <p>代表取締役 CEO</p>
              <p>ZAK</p>
            </div>
            <div class="career">
              <p class="member">花崎 茂晴   Shigeharu Hanazaki  （共同創業者）</p>
              <p>東京大学経済学部卒業後三井物産株式会社に入社。ロジスティクス関連事業に従事後、新設の情報産業部門にて米 AOL社との日本合弁事業立上げに携わる。2001年に社内プロジェクトとして立ち上げた米国LinkShare社との合弁事業を2004年に法人化、同社代表として業界（アフィリエイトマーケティング）最大規模に成長させた。2011年より米国東海岸の I C T 事業部門責任者としてNYに駐在、米国IT企業への事業投資・事業開発をリード。帰国後、AOLプラットフォームズジャパン代表取締役CEOを経て、三井物産を退社。2017年菱洋エレクトロ社IoT事業管掌執行役員、2018年よりBig Data (POSデータ)を活用したリテールマーケティングを提供するカタリナマーケティングジャパン社の代表取締役社長を歴任。米ノースウエスタン大学KelloggビジネススクールAEP修了。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/mas.png">
              <p>Co-Founder & CSO</p>
              <p>MAS</p>
            </div>
            <div class="career">
              <p class="member">田中 正道   Masamichi Tanaka  （共同創業者）</p>
              <p>米ラトガース大学工学部卒業後、キヤノン株式会社入社、キヤノンヨーロッパに赴任し、半導体技術者としてドイツ、イタリアメーカーへの半導体技術導入を担当。2000年にソニー株式会社入社しVAIO同梱ソフトウェア、イメージステーション等オンラインサービスの企画・コンスーマ製品の商品企画担当しHDDレコーダ等 新規軸商品を創出。全社共通プラットフォーム中期計画策定に従事。2006年に米マイクロソフト入社し、開発者製品PM、開発者コミュニティエンゲージメントチームを統括。2010年にBULB株式会社を設立、2013年に株式会社ウフルとの経営統合、ウフル取締役に就任。大手製造メーカーを中心としたデジタルトランスフォメーションコンサルティングを担当。青山学院ビジネススクール修了、同MBAにおけるイノベーションマネジメント等の講師も務める。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/shu.png">
              <p>取締役 COO</p>
              <p>SHU</p>
            </div>
            <div class="career">
              <p class="member">桑田 修吉   Shukichi Kuwata  （共同創業者）</p>
              <p>関西学院大学法学部政治学科卒業。株式会社電通入社後、プロモーション支援、アカウント・プロジェクト開発、クリエイティブプロデュース、メディアリレーション担当部長等を担当。2015年から株式会社ウフルに経営参画。取締役副社長COOとして、SI/ライセンス事業拡大やIoT事業立ち上げ、複数のM&A、大手事業会社等との資本業務提携等を含む業容拡大に取り組む。2019年同社取締役を退任後、複数のスタートアップ企業への投資・経営参画や協創型プラットフォーム創設に取り組む過程でINDIVISIONを共同創業する。（BULB株式会社取締役）</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/toshi.png">
              <p>取締役 CFO</p>
              <p>Toshi</p>
            </div>
            <div class="career">
              <p class="member">渋佐  寿彦   Toshihiko Shibusa</p>
              <p>公認会計士・税理士。新日本監査法人（現EY新日本有限責任監査法人）にて上場企業の監査を中心に経験をした後に独立開業。2007年に虎ノ門会計グループ代表（現任）。2009年に虎ノ門有限責任監査法人設立、理事長就任（現任）。健全な発展をサポートするため、様々なステージの会社にアドバイザリー業務や監査業務にて携わる他、社外取締役や社外監査役を多数務める。また、2019年に家業を事業承継することとなり、福島県南相馬市の都市ガス事業を中心とする相馬ガスグループの代表取締役に就任（現任）。2021年にはライスレジンを製造するバイオマスレジン福島を設立し、東日本大震災で全町避難となった浪江町での事業をスタートした。日本公認会計士協会  理事（中小企業支援担当）。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/shao.png">
              <p>事業開発ディレクター</p>
              <p>SHAO</p>
            </div>
            <div class="career">
              <p class="member">シャオミン  シャオ   Xiaoming Shao</p>
              <p>広告テクノロジーのスペシャリスト。2000年よりデジタルマーケティング業界に従事、20年の業界経験を持つ。Doubleclick、Overture、RightMedia、AudienceScience、MOATなどデジタルマーケティングのリーディングカンパニーで経験を積み、2017年に独立。現在、海外、主に欧米のアドテク系ベンダーの日本市場への導入支援を中心に活動中。アジアパシフィックマーケットにおいてDoubleClickの広告配信システム、Yahoo!IncのRightMedia Exchange、オーディエンスサイエンスのデータ管理システムなど16件の新規事業立ち上げ経験を持つ。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/ikumi.png">
              <p>Head of Eventory Japan</p>
              <p>Ikumi</p>
            </div>
            <div class="career">
              <p class="member">片岡 郁美   Ikumi Kataoka</p>
              <p>上智大学外国語学部を卒業後、ドイツの日系旅行代理店で勤務し、ルクセブルグの日系銀行にて勤務。2013年にHEC ParisでMBAを取得後、パリ発ライフスタイルボックスのサブスクリプションサービスの日本法人を創業し、代表取締役に就任。アクティブユーザー数35,000人以上のサービスに発展。2021年1月より現職。</p>
            </div>
          </li>
          <li>
            <div class="title">
              <img src="../assets/img/satoshi.png">
              <p>シニアアドバイザー</p>
              <p>Satoshi</p>
            </div>
            <div class="career">
              <p class="member">佐伯 諭   Satoshi Saeki</p>
              <p>1998年早稲田大学大学院理工学研究科修了。SIerでのプログラマー＆SEを7年経験の後、外資系金融で金融アナリストとして従事。
2007年より電通にてデジタルマーケティング領域でクライアントPDCA支援やソリューション開発などを担当。電通デジタル創業期に執行役員CDOとしてデータ戦略や組織育成に貢献した後、2021年より独立。DX領域・データプライバシー領域のコンサルタント業務と社団法人データサイエンティスト協会の活動を通し、デジタル人材の教育、育成事業を支援中。</p>
            </div>
          </li>
        </ul> 
        <table class="companytable">
          <tbody>
            <tr>
              <td class="title">法人名：</td>
              <td Class="text">株式会社インディヴィジョン</td>
            </tr>
            <tr>
              <td class="title">所在地：</td>
              <td Class="text">〒106-0047  東京都港区南麻布3丁目20-1   Daiwa麻布テラス5F</td>
            </tr>
            <tr>
              <td class="title">設立日：</td>
              <td Class="text">2020年4月13日</td>
            </tr>
            <tr>
              <td class="title">取締役：</td>
              <td Class="text">代表取締役CEO 花崎茂晴</td>
            </tr>
            <tr>
              <td class="title"></td>
              <td Class="text">取締役COO 桑田修吉</td>
            </tr>
            <tr>
              <td class="title"></td>
              <td Class="text">取締役CFO 渋佐寿彦</td>
            </tr>
            <tr>
              <td class="title">事業概要：</td>
              <td Class="text">企業と個人を結ぶエンゲージメントプラットフォームの提供・運用・コンサルティング</td>
            </tr>
            <tr>
              <td class="title">お問合せ：</td>
              <td Class="text"><a href="mailto:contact@indivision.io">contact@indivision.io</a></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="products">
        <div class="pagetitle">PRODUCTS</div>
        <p class="productstitle">優れたDXを実現する世界最先端プラットフォーム製品群</p>
        <ul class="productlist">
          <li>
            <div class="image">
              <img src="../assets/img/relay42.jpg">
            </div>
            <div class="imagecountry">
              <img src="../assets/img/nederlan.svg">
            </div>
            <div class="content">
              <p class="title">Intelligent Journey Orchestration</p>
              <p class="text">すべてのシステムとタッチポイントへのリアルタイム接続を備えたAI駆動のカスタマージャーニー用に構築された世界唯一のプラットフォーム</p>
            </div>
          </li>
          <li>
            <div class="image">
              <img src="../assets/img/eventory.png">
            </div>
            <div class="imagecountry">
              <img src="../assets/img/usa.svg">
            </div>
            <div class="content">
              <p class="title">Hybrid Event DX Platform</p>
              <p class="text">イベントのあらゆる工程を一元管理し、参加者のアクティビティをデータ化する「イベントDXソリューション」</p>
            </div>
          </li>
          <li>
            <div class="image">
              <img src="../assets/img/jh.png">
            </div>
            <div class="imagecountry">
              <img src="../assets/img/japan.svg">
            </div>
            <div class="content">
              <p class="title">Individual Journey Visualizer</p>
              <p class="text">製造・流通・販売・CSなどあらゆる事業課題のリアルタイム可視化を実現する究極のビジュアライザー</p>
            </div>
          </li>
        </ul>
      </div>

      <div id="footer">Copyright © INDIVISION Inc. All Rights Reserved.</div>
    </div>
    <transition name="fade">
      <div id="pagetop" v-show="pageTopY > 300" @click="pageTop"></div>
    </transition>

    <el-drawer
      id="menuArea"
      :visible.sync="hamburgerMenu"
      :show-close="true"
      :with-header="false">
      <div class="menuAreaClose">
        <el-button id="hamburgerClose" icon="el-icon-close" circle @click="hamburgerMenu = !hamburgerMenu"></el-button>
      </div>
      <div class="hamburger_item">
        <ul id="nav">
          <li><span  @click="scroll('#vision')">VISION</span></li>
          <li><span  @click="scroll('#news')">NEWS</span></li>
          <li><span  @click="scroll('#solution')">SOLUTION</span></li>
          <li><span  @click="scroll('#about')">ABOUT US</span></li>
          <li><span  @click="scroll('#products')">PRODUCTS</span></li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<style scoped>
#pagetop{
  background-image: url("../assets/img/up.svg");
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;
  z-index: 1000;
  cursor: pointer;
}

#pagetop:hover{
  opacity: 0.7;
}

#menu{
  position: fixed;
  display: flex;
  height: 70px;
  width: 100%;
  border-bottom: #FFFFFF 1.5px solid;
  background-color: #1DADE5;
  z-index: 10;
}

#menu img{
  height: 38px;
  margin-top: 16px;
  margin-left: 24px;
  cursor: pointer;
}

#menu img:hover{
  opacity: 0.7;
}

#menu .menu_item{
  height: 70px;
  width: 60%;
  position: absolute;
  right: 0px;
  text-align: end;
}

#footer{
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #FFFFFF;
  font-size: 12px;
}

.el-drawer{
  width: 300px !important;
}

#hamburger, #hamburgerClose{
  background-color:#1DADE5 !important;
  border: #1DADE5 !important;
  color: #FFFFFF;
  font-size: 27px;
  height: 68px;
  text-align: center;
  vertical-align: middle;
  padding: 8px 10px;
  border-radius: 0px;
  margin-left: 10px;
  right: 20px;
  position: absolute;
}

#hamburger{
  display: none;
}

#hamburgerClose{
  background-color:#1DADE5 !important;
  border-color: #1DADE5 !important;
  position: absolute;
  right: 0px;
}

.menuAreaClose{
  height: 60px;
}

#nav {
  list-style: none;
  overflow: hidden;
  margin: 0;
}
 
#nav li{
  width: 19%;
  text-align: center;
  float: left;
  height: 70px;
  line-height: 70px;
  margin-right: 2px;
  font-size: 16px;
}

.hamburger_item #nav li{
  text-align: left;
  width: 100%;
  float: none;
}


#nav li span {
  color: #fff;
  font-weight: 500;
  position: relative;
  display: inline-block;
  transition: .3s;
  cursor: pointer;
}

#nav li span::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  width: 0;
  height: 3px;
  background-color: #FFFFFF;
  transition: .3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#nav li span:hover::after {
  width: 100%;
}

#header{
  position: relative;
  width: 100%;
  height: 51vw;
  top: 70px;
  background-image: url("../assets/img/top_bg_logo.svg"), url("../assets/img/top_bg.webp");
  background-repeat: no-repeat;
  background-position: center 55%, center center;
  background-size: 30%, 100%;
  z-index: 1;
}

#header .header_title{
  color: #FFFFFF;
  font-size: 2.93vw;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 7.32vw;
  text-shadow: 1px 1px 9px rgba(12, 12, 119, 0.75), -1px -1px 9px rgba(12, 12, 119, 0.75);
}

#main{
  width: 100%;
  height: auto;
  background-image: url("../assets/img/background-image.svg");
  background-size: 120%;
  background-position: center 0;
}

#vision,
#news,
#solution,
#about,
#products{
  position: relative;
  padding-top: 100px;
}

#news .list{
  width: 70%;
  margin: 30px auto;
  overflow-y: auto;
}

#news .releasedate{
  color: #2E3192;
  font-size: 16px;
  font-weight: 700;
}

#news .title{
  margin-bottom: 30px;
}

#news .title a{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 75px;
}

#news .title a:hover{
  text-decoration: underline;
}

#news .pastNewsButton{
  width: 140px;
  height: 34px;
  color: #FFFFFF;
  background-color: #0071BC;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  float: right;
  margin-top: 30px;
}

#news .pastNewsButton:hover{
  background-color: #1085c6;
}

.pagetitle{
  width: 150px;
  height: 40px;
  color: #1DADE5;
  background-color: #FFFFFF;
  margin: 30px auto;
  text-align: center;
  line-height: 40px;
  font-size: 21px;
  font-weight: 500;
}

.newsarea{
  background-color: #0071BC;
  height: 80px;
  display: inline-flex;
  width: 100%;
}

.newsarea .title{
  width: 180px;
}

.newsarea .newstitle{
  width: 100px;
  height: 30px;
  color: #0071BC;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
  left: 40px;
  top: 25px;
  position: relative;
}


.newsarea .list{   
  position: relative;
  right: 5px;
  height: 60px;
  margin: 10px 0;
  width: calc(100% - 160px);
  font-size: 15px;
  color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  direction: rtl;
  padding-left: 20px;
}

.newsarea .list::-webkit-scrollbar {
  width: 8px;
  height: 20px;
  background: #1C4C7D;
}

.newsarea .list::-webkit-scrollbar-track {
  background: #1C4C7D;
  border-radius: 2px;
}

.newsarea .list::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 0.95);
  border-radius: 2px;
}

.newsarea .item{   
  width: 100%;
  height: auto;
  display: inline-flex;
  margin: 3px 0;
  direction: ltr;
}

.newsarea .item .releasedate{   
  margin-right: 20px;
  white-space: nowrap;
}

.newsarea .item .title{
  width: auto;
}

.newsarea .item .title a{   
  color: #FFFFFF;
  text-decoration: none;
}

.newsarea .title a:hover{   
  text-decoration: underline;
}

.visiontext{
  margin: 40px;
  color: #FFFFFF;
  font-size: 16px;
}

.visiontitle,
.productstitle{
  width: 73.2%;
  height: 32px;
  margin: 30px auto;
  margin-bottom: 60px;
  letter-spacing: 0.1em;
  -moz-text-align-last: justify;
  text-align-last: justify;
  text-justify:inter-ideograph;
  text-align: center;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 500;
}

.productstitle{
  width: 80%;
  margin-bottom: 30px;
}

.visioArea{
  width: 73.2%;
  height: 100px;
  margin: 30px auto;
}


.visioArea div{
  float: left;
  height: 100px;
}

.visioArea .visionmean,
.visioArea .operator
{
  color: #FFFFFF;
  text-align: center;
  width: 20%;
  line-height: 1.6;
}

.visioArea .visionmean .word
{
  font-size: 30px;
  font-weight: 700;
}
.visioArea .visionmean .mean
{
  font-size: 20px;
  font-weight: 500;
}

.visioArea .operator
{
  font-size: 36px;
  font-weight: 400;
  width: 15%;
}

.visioArea .logo
{
  width: 30%;
}


.visiontable{
  width: 73.2%;
  margin: 30px auto;
}

.visiontable td{
  padding: 10px 10px;
}

.visiontable .vision{
  width: 30%;
  font-size: 28px;
}

.visiontable .visioncontent{
  width: 70%;
  font-size: 20px;
  font-weight: 500;
}

.solutionlist,
.memberlist,
.productlist {
  list-style: none;
  display: flex;
  padding: 0;;
}

.solutionlist{
  padding-top: 10px;
}
 
.solutionlist li{
  width: 45%;
  float: left;
  margin: 0 2.5%;
  color: #FFFFFF;
}

.solutionlist li .date{
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 0;
}
.solutionlist li img{
  width: 100%;
  height: auto;
  object-fit: contain;
}

.solutionlist li img:hover{
  opacity: 0.7;
}

.solutionlist li .text{
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
}

.solutionlist li .text a{
  color: #FFFFFF;
  cursor: pointer;
}

.memberlist {
  display: inline;
}

.memberlist li{
  margin: 0 5%;
  display: flex;
  margin-bottom: 80px;
}

.memberlist li .title{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.memberlist li .title p{
  margin: 5px;
  width: 220px;
}
.memberlist li .title img{
  width: 200px;
  height: 200px;
  border-radius: 75px;
}

.memberlist li .career{
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin-left: 100px;
  line-height: 2.0;
}

.memberlist li .member{
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  margin-top: 0px;
}

.companytable{
  width: 90%;
  margin: 0 auto;
}

.companytable td{
  color: #000000;
  font-size: 17px;
  padding: 5px;
  vertical-align: top;
}

.companytable .title{
  width: 30%;
  text-align: right;
  font-weight: 300;
}

.companytable .text{
  width: 70%;
  font-weight: 400;
  padding-left: 20px;
}

.companytable .text a{
  color: #000000;
}

.productlist {
  display: inline;
}

.productlist li{
  margin: 0 5%;
  display: flex;
  margin-bottom: 40px;
}

.productlist li .image,
.productlist li .imagecountry{
  display: flex;
  align-items: flex-end;
}

.productlist li .image img{
  width: 120px;
  height: 120px;
  background-color: #FFFFFF;
}
.productlist li .imagecountry img{
  width: 60px;
  height: 60px;
  bottom: 0px;
  margin-left: 10px;
}

.productlist li .content{
  margin-left: 30px;
}

.productlist li .title{
  border: #FFFFFF 1px solid;
  color: #FFFFFF;
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  width: 390px;
  height: 40px;
  line-height: 40px;
}

.productlist li .text{
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  height: 60px;
}

@media screen and (max-width: 980px) {
  .visioArea .visionmean
  {
    width: 40%;
  }

  .visioArea .operator
  {
    font-size: 25px;
    width: 20%;
  }

  .visioArea .visionmean .word
  {
    font-size: 20px;
  }

  .visioArea .visionmean .mean
  {
    font-size: 16px;
  }

  .visioArea .operator.eq{
    text-align: right;
    width: 50%;
  }

  .visioArea .logo
  {
    text-align: right;
    width: 45%;
  }

  .visioArea .logo img
  {
    width: 150px;
  }
}

@media screen and (max-width: 1150px) {
  .productstitle{
    font-size: 28px;
  }
}

@media screen and (max-width: 980px) {
  .productstitle{
    font-size: 24px;
  }
}

@media screen and (max-width: 840px) {
  .visiontitle,
  .productstitle{
    font-size: 20px;
  }

  .newsarea{
    height: 50px;
  }

  .newsarea .title{
    width: 120px;
  }

  .newsarea .newstitle{
    width: 80px;
    height: 25px;
    line-height: 25px;
    left: 15px;
    top: 14px;
  }

  .newsarea .list{
    right: 5px;
    height: 40px;
    margin: 5px 0;
    width: calc(100% - 120px);
    font-size: 10px;
  }

  .newsarea .item{
    margin: 2px 0;
  }
  .newsarea .list::-webkit-scrollbar {
    width: 6px;
  }
}

@media screen and (max-width: 720px) {
  body{
    overflow-x: hidden !important;
  }

  .menu_item{
    display: none;
    right: -9999px !important;
  }

  #hamburger{
    display: block;
  }
  .visiontext{
    font-size: 14px;
  }

  #news .title a{
    font-size: 14px;
  }

  .visiontitle,
  .productstitle{
    font-size: 20px;
    letter-spacing: 0.1em;
    width: 90%;
  }

  .visiontitle{
    -moz-text-align-last: auto;
    text-align-last: auto;
  }

  .productstitle{
    text-align-last: auto;
    margin-bottom: 60px;
  }

  .visiontable .vision
  {
    font-size: 20px;
  }

  .visiontable .visioncontent
  {
    font-size: 14px;
  }

  .solutionlist {
    width: 90%;
    padding-left: 5%;
  }

  .solutionlist li .date{
    font-size: 16px;
  }
  .solutionlist li .text{
    font-size: 15px;
  }

  .memberlist li{
    display: block;
  }

  .memberlist li .title p {
    margin: 0 auto;
  }

  .memberlist li .career {
     margin-left: 10px;
     font-size: 13px;
  }

  .companytable td{
    font-size: 13px;
  }

  .productlist li{
    display: block;
  }

  .productlist li .image, .productlist li .imagecountry{
    display: inline-block;
  }

  .productlist li .content{
    margin-left: 0px;
  }
  .productlist li .title{
    font-size: 18px;
    width: 100%;
  }

  .productlist li .text{
    font-size: 14px;
  }
}

</style>

<style>
#menuArea div > div{
  outline : none;
  width: 200px !important;
  height: 100%;
}
#menuArea section{
  background-color:#1DADE5 !important;
}

.el-popup-parent--hidden{
  overflow: unset;
}
</style>

<script>
import VueScrollTo from 'vue-scrollto'
import { mapState, mapActions } from "vuex";

export default {
  name: 'top',
  data(){
    return {
      pageTopTimer: 0,
      pageTopY: 0,
      hamburgerMenu: false
    }
  },
  async created() {
    // firebase auth
    await this.auth()
    await this.getNews()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    ...mapState([
      'news',
    ]),
    getTop5News(){
      return this.news.slice(0, 5)
    }
  },
  methods: {
    ...mapActions({
      auth: "auth",
      getNews: "getNews"
    }),
    handleScroll() {
        if (this.pageTopTimer) return;
        this.pageTopTimer = setTimeout(() => {
          this.pageTopY = window.scrollY;
          clearTimeout(this.pageTopTimer);
          this.pageTopTimer = 0;
        }, 100);
    },
    pageTop(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    scroll(element) {
      this.hamburgerMenu = false
      VueScrollTo.scrollTo(element)
    },
    // newsPageへ遷移
    newsPage(){
      this.$router.push('/news')
    }
  }
}
</script>
