<template>
  <div id="notfound">
      <div id="main">
        <div class="text">404 Not Found</div>
        <div class="text">お探しのページは見つかりませんでした。</div>
        <div class="back text"><a href="/">https://indivision.io</a></div>
      </div>
  </div>
</template>

<style scoped>
#main{
  width: 100%;
  height: 1000px;
  background-image: url("../assets/img/background-image.svg");
  background-size: 120%;
  background-position: center 0;
  overflow: hidden;
}


.text{
    color: #FFFFFF;
    font-size: 32px;
    padding: 20px;
    width: 90%;
    text-align: center;
    margin: 0 auto;
}

.text a:link, a:visited, a:hover, a:active {
  color: #FFFFFF;
}
</style>

<script>

export default {
  name: 'notfound',
  methods: {
  }
}
</script>
