import firebase from "firebase";

const settings = {
    timestampsInSnapshots: true,
    merge: true 
};

const config = {
    apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config)
firebase.firestore().settings(settings)
const db = firebase.firestore()
const auth = firebase.auth()
const timeStamp = firebase.firestore.Timestamp

export {
    firebase,
    db,
    auth,
    timeStamp
};
